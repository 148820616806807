import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Form, Input, message, Modal, notification, Select, Space, Upload } from "antd";
import DatePicker from "react-datepicker";
import { Country } from "country-state-city";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
import upload_icon from "../assests/images/upload_icon.png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Undo,
  Link,
  Heading,
  List,
  Alignment,
  Indent,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import countryList from "./CountryList";
import { setSelectedProject } from "../Redux/features/project.slice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LocalDiningOutlined } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

export const CreateProjectModal = ({
  getProjectList = () => { },
  getholdProjectList = () => { },
  getcompleteProjectList = () => { },
  setCreateModal = () => { },
  open = false,
  setOpen = () => { }
}) => {
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [form] = Form.useForm()
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [value, setValue] = useState("");
  const [remark, setRemark] = useState("");
  const [inputList, setInputList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [deletedfileList, setdeletedFileList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  // const [isSubscribed, setIsSubscribed] = useState(false);
  // const [, setCall_recorded] = useState(0);
  const countries = Country?.getAllCountries();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = React.useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const dispatch = useDispatch();
  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });
  const handleClose = React.useCallback(() => {

    form.resetFields()
    setEndDate("");
    setStartDate("");
    setValue("");
    setRemark("")
    setOpen(false)
    setSpinner(false)
    setEmailList([]);
    setOpen(false)
    dispatch(setSelectedProject({}));
    setInputList([]);
    setSelectList([]);
    setFileList([])
    setProjectDetails({
      project_title: "",
      expected_calls: "",
    });

  }, [dispatch, form, setOpen]);

  const handelChange = React.useCallback(
    (e) => {
      let key = e.target.name;
      let value = e.target.value;
      setProjectDetails({ ...projectDetails, [key]: value });
    },
    [projectDetails]
  );
  const handleEditorChange = React.useCallback((content) => {
    setValue(content);
  }, []);


  const uploadFiles = useCallback(
    async (project_id) => {
      let filterFilesToBeUpload = fileList?.filter(
        (file) => file?.originFileObj
      );
      if (filterFilesToBeUpload?.length > 0) {
        try {
          const formdata = new FormData();
          filterFilesToBeUpload?.forEach((file) => {
            if (file?.originFileObj) {
              formdata.append("attachment", file?.originFileObj);
            }
          });
          formdata.append("project_id", project_id);
          await axios.post(`${API_PATH.UPLOAD_FILE}`, formdata, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (error) {
          // setLoading(false);
          return notification.error({
            message: error?.response?.data?.message,
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    },
    [fileList]
  );

  const handleSubmit = React.useCallback(
    async (e) => {
      // e.preventDefault();
      setSpinner(true);

      let countryData = [];

      let companyData = [];
      let emailData = [];
      // if (loginDetails?.user?.email) {
      //   emailData?.push(loginDetails?.user?.email?.split("@")[0]);
      // }

      // for (var Count in selectList) {
      //   countryData?.push(selectList[Count]?.country);
      // }

      // for (var Company in inputList) {
      //   companyData?.push(inputList[Company]?.company);
      // }

      // for (var index in emailList) {
      //   if (
      //     emailList?.[index]?.value !== emailData?.[0] &&
      //     emailList?.[index]?.value !== ""
      //   ) {
      //     if (!emailData?.includes(emailList?.[index]?.value?.split("@")?.[0])) {
      //       emailData?.push(emailList?.[index]?.value?.split("@")?.[0]);
      //     }
      //   }
      // }
      let payload = {
        project_title: projectDetails?.project_title,
        user_id: loginDetails?.user?._id,
        poc_email: loginDetails?.user?.email,
        invite_team: loginDetails?.user?.email && loginDetails?.user?.email?.split("@")?.[0] ?
          [...new Set([...emailList?.map((list) => list?.split("@")?.[0]), loginDetails?.user?.email?.split("@")?.[0]])]?.join(",") : emailList?.map((list) => list?.split("@")?.[0])?.join(","),
        country: selectList?.join(","),
        target_companies: inputList?.join(","),
        question: value,
        project_deadline: endDate !== "" ? endDate?.toISOString() : "", // Convert to ISO string 
        project_start_date: startDate !== "" ? startDate?.toISOString() : "", // Convert to ISO string  //new field
        expected_calls: projectDetails?.expected_calls,
        status: 1,  //new field active status
        client_name: userData?.user?.company_id?.companyName || userData?.user?.company,//new field
        remarks: remark
      };
      // if (
      //   payload.project_title === "" ||
      //   payload.expected_calls === "" ||
      //   payload.question === "" ||
      //   payload.project_deadline === ""
      // ) {
      //   return toast.warning("All fields are mandatory!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // }
      try {
        const res = await fetch(`${API_PATH?.CREATE_PROJECT}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (
          result?.status === true &&
          result?.message === "Insert successfully."
        ) {
          return result;
          // setSpinner(false);
          // getProjectList();
          // getholdProjectList();
          // getcompleteProjectList();
          // return toast.success("Project created successfully", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 3000,
          // });
        }
        //  else {
        //   return toast.error(result.message, {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      } catch (error) {
        setSpinner(false);
        return toast.error("Something went wrong. Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [projectDetails?.project_title, projectDetails?.expected_calls, loginDetails?.user?._id, loginDetails?.user?.email, emailList, selectList, inputList, value, endDate, startDate, userData?.user?.company, remark, headers]
  );
  const getUsers = React.useCallback(async () => {
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${userData?.user?.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        setUserList([...res.data.data]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [headers, userData?.user?.company_id._id]);
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Modal open={open}
        title={
          <div>
            <h4 className="mb-0"> Create New Project</h4>
            <p
              className="mb-0"
              style={{ color: "#526fd7", fontSize: "20px !important" }}
            >
              {" "}
              Detail out your requirement to launch a custom recruitment
              request{" "}
            </p>
          </div>
        }
        centered
        width={800}
        style={{
          height: 550,
          overflowY: "scroll"
        }}
        onCancel={() => {
          handleClose()
        }}

        footer={false}
      >
        <div >
          <Form form={form}
            autoComplete="off"
            onFinish={async () => {
              let res = await handleSubmit()
              if (res) {
                let project_id = res?.data
                await uploadFiles(project_id);
              }
              setSpinner(false);
              getProjectList();
              handleClose();
              getholdProjectList();
              getcompleteProjectList();
            }} name="create_project_form">
            <Row className="">
              <Col md={12} className="">
                <Form.Item labelAlign="left" className="labelTop" name="project_title" label="Project Title" rules={[
                  {
                    required: true,
                    message: "Project Title is required",
                  },
                ]}>
                  <Input
                    type="text"
                    size="large"
                    placeholder="Project Title"
                    name="project_title"
                    value={projectDetails.project_title}
                    onChange={handelChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="">


              <Col md={6} className="">
                <Form.Item labelAlign="left" autoComplete="off" className="labelTop" name="project_start_date" label="Project Start Date" rules={[
                  {
                    required: true,
                    message: "Project Start Date is required",
                  },
                ]}>

                  <DatePicker
                    style={{ cursor: "default" }}
                    // inputProps={{ readOnly: true }}
                    className="form-select"
                    autoComplete='off'
                    minDate={new Date()}
                    value={startDate}
                    selected={startDate}
                    placeholderText="MM/DD/YYYY"
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>

              </Col>
              <Col md={6} className="">
                <Form.Item autoComplete="off" labelAlign="left" className="labelTop" name="project_deadline" label="Project Deadline" rules={[
                  {
                    required: true,
                    message: "Project Deadline is required",
                  },
                ]}>

                  <DatePicker
                    style={{ cursor: "default" }}
                    // inputProps={{ readOnly: true }}
                    className="form-select"
                    minDate={new Date()}
                    autoComplete='off'
                    value={endDate}
                    selected={endDate}
                    placeholderText="MM/DD/YYYY"
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={8} xl={6}>
                <Form.Item labelAlign="left" className="labelTop" label="Documents" name="attachement">
                  <Dragger
                    className="w-100 document_uploader"
                    action={false}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      // Simulate an upload request
                      setTimeout(() => {
                        onSuccess("ok"); // Simulate successful upload
                      }, 1000);
                    }}
                    beforeUpload={async (file) => {
                      const isLt10M = file.size / 1024 / 1024 < 10;
                      if (!isLt10M) {
                        message.error({
                          content: "File must be smaller than 10MB",
                          style: {
                            fontSize: "18px", // Increase the font size
                            padding: "20px", // Increase the padding
                          },
                        });
                        form.setFields([
                          {
                            name: "attachement",
                            errors: [`File must be smaller than 10MB.`],
                          },
                        ]);
                      }
                      const isPNG = file.type === "image/png";
                      const isJPEG = file.type === "image/jpeg";
                      const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                      const isPDF = file.type === "application/pdf";
                      const isCSV = file.type === "text/csv";
                      if (!isPNG && !isJPEG && !isPDF && !isWordsheet && !isSpreadsheet && !isCSV) {
                        form.setFields([
                          {
                            name: "attachement",
                            errors: [`Please upload a file of a PDF, PNG, CSV, Xlsx, docx or JPEG format.`],
                          },
                        ]);
                      }
                      return isPNG || isJPEG || isPDF || isSpreadsheet || isWordsheet || isCSV || isLt10M || Upload.LIST_IGNORE;
                    }}
                    onRemove={async (file) => {
                      setdeletedFileList([...deletedfileList, file]);
                      setFileList(fileList?.filter((f) => f.uid !== file.uid));
                      // await deleteProject(file)
                    }}
                    onChange={({ fileList }) => {
                      let list = fileList
                        ?.map((file) => {
                          if (file?.size) {
                            const isLt10M = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB
                            const isPNG = file.type === "image/png";
                            const isJPEG = file.type === "image/jpeg";
                            const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                            const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                            const isPDF = file.type === "application/pdf";
                            const isCSV = file.type === "text/csv";
                            if ((isPNG || isJPEG || isPDF || isWordsheet || isSpreadsheet || isCSV) && isLt10M) {
                              return file
                            }
                          } else {
                            return file;
                          }
                        })
                        ?.filter((li) => li !== undefined);
                      setFileList(list);
                    }}
                    multiple={true}
                    fileList={fileList}
                  >
                    <img
                      src={upload_icon}
                      style={{ width: "60px" }}
                      alt="Upload Icon"
                    />
                    <p className="ant-upload-text pb-0 mt-4">
                      Please upload all relevant documents regarding the project
                    </p>
                    <small className="text-muted">
                      Maximum file size 10MB each
                    </small>
                  </Dragger>
                  {/* <Upload

                    action={false}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      // Simulate an upload request
                      setTimeout(() => {
                        onSuccess("ok"); // Simulate successful upload
                      }, 1000);
                    }}
                    beforeUpload={async (file) => {
                      // Optional validation before uploading (like file size/type check)
                      return true; // Return false to prevent upload
                    }}
                    onChange={({ fileList }) => {
                      // Update file list on change
                    }}
                    multiple={true}
                    fileList={fileList}

                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                </Form.Item>
              </Col>
            </Row>
            <Row className="">
              <Col md={6} className="">
                <Form.Item labelAlign="left" className="labelTop" name="geography_of_experts" label="Geography Of Experts" rules={[
                  {
                    required: true,
                    message: "Geography of experts is required",
                  },
                ]}>

                  <Select
                    mode="multiple"
                    name="geography_of_experts"
                    showSearch
                    filterOption={(input, option) =>
                      option?.value?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="w-100"
                    options={countryList}
                    getPopupContainer={trigger => trigger.parentElement}
                    value={selectList}
                    placeholder="Select Country"
                    onChange={(value) => {
                      setSelectList(value)
                    }}
                  >
                    <>
                      <Select.Option key={0} value={"Global"} label={"Global"}>
                        Global
                      </Select.Option>
                      <Select.Option key={0} value={"SAARC"} label={"SAARC"}>
                        SAARC
                      </Select.Option>
                      <Select.Option key={1} value={"Asia"} label={"Asia"}>
                        Asia
                      </Select.Option>
                      <Select.Option
                        key={2}
                        value={"Middle East"}
                        label={"Middle East"}
                      >
                        Middle East
                      </Select.Option>
                      <Select.Option key={3} value={"Europe"} label={"Europe"}>
                        Europe
                      </Select.Option>
                      <Select.Option
                        key={4}
                        value={"North America"}
                        label={"North America"}
                      >
                        North America
                      </Select.Option>
                      <Select.Option
                        key={5}
                        value={"South America"}
                        label={"South America"}
                      >
                        South America
                      </Select.Option>
                    </>
                    {countries?.map((country, index) => (
                      <Select.Option
                        key={index + 5}
                        value={country?.name}
                        label={country?.name}
                      >
                        {`${country?.name}`}
                      </Select.Option>
                    ))}</Select>
                </Form.Item>

              </Col>
              <Col md={6} className="col-md-6">
                <Form.Item labelAlign="left" className="labelTop" name="target_companies" label="Target Companies" rules={[
                  {
                    required: true,
                    message: "Target Companies are required",
                  },
                ]}>

                  <Select
                    mode="tags"
                    className="w-100"
                    open={false}
                    value={inputList}
                    getPopupContainer={trigger => trigger.parentElement}
                    placeholder="Target Companies"
                    onChange={(value) => {
                      setInputList(value)
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={6} className="">
                <Form.Item labelAlign="left" className="labelTop" name="expected_calls" label="Expected Calls" rules={[
                  {
                    required: true,
                    message: "Expected Calls is required",
                  },
                ]}>

                  <Select
                    name="expected_calls"
                    className="w-100"
                    placeholder="Expected Calls"
                    getPopupContainer={trigger => trigger.parentElement}
                    value={projectDetails.expected_calls || null}
                    onChange={(value) => {
                      setProjectDetails({ ...projectDetails, expected_calls: value });
                    }}
                    size="large"
                  >
                    {Array.from(Array(200).keys())?.map((item) => {
                      return <Select.Option value={item + 1}>{item + 1}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col md={6}>
                <Form.Item labelAlign="left" className="labelTop" name="team_member" label="Team Members"
                // rules={[
                //   {
                //     required: true,
                //     message: "Team members are required",
                //   },
                // ]}
                >

                  <Select
                    mode="multiple"
                    className="w-100"
                    name="Select Team Member"
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    } placeholder="Select Team Member"
                    value={emailList}
                    options={userList?.filter((li) => li?.email !== userData?.user?.email)?.map((item) => ({
                      label: item?.email,
                      value: item?.email,
                      key: item?._id,
                    }))}
                    onChange={(value) => {
                      setEmailList(value);
                    }}
                    dropdownRender={
                      (menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space.Compact
                            block
                            style={{ padding: "0 8px 4px", width: "100%" }}
                            className="zum_meeting_space"
                          >
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              className="primary w-100"
                              onClick={(e) => {
                                setCreateModal(true)
                                setOpen(false)
                              }}
                            >
                              Add User
                            </Button>
                          </Space.Compact>
                        </>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="col-lg-12">
                <div className="form-group">
                  <div className="position-relative ckeditor_client">
                    <Form.Item labelAlign="left" className="labelTop" name="overview" label="Project Overview" rules={[
                      {
                        required: true,
                        message: "Project Overview is required",
                      },
                    ]}>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          // editorRef.current = editor;
                          console.info("Editor is ready to use!", editor);
                        }}
                        data={value}
                        config={{
                          toolbar: {
                            items: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "alignment:left",
                              "alignment:center",
                              "alignment:right",
                              "alignment:justify",
                              "|",
                              "bulletedList",
                              "numberedList",
                            ],
                          },
                          plugins: [
                            Bold,
                            Essentials,
                            Italic,
                            Paragraph,
                            Undo,
                            Link,
                            Heading,
                            List,
                            Alignment,
                            // BulletedList,
                            // NumberedList,
                            Indent,
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                          alignment: {
                            options: ["left", "center", "right", "justify"],
                          },
                        }}
                        onChange={(event, editor) => {
                          const newData = editor?.getData();
                          handleEditorChange(newData);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Item label="Additional Notes (Optional)" labelAlign="left" className="labelTop" name="remarks">
                  <Input
                    type="text"
                    size="large"
                    name="remarks"
                    value={remark}
                    onChange={(event) =>
                      setRemark(event.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <div className="radio2">
              <Checkbox
                className="me-2"
                onChange={handelChecked}
                value={isSubscribed}
                name="call_recorded"
                style={{ color: "#526fd7" }}
                required
              ></Checkbox>

              <label style={{ color: "#526fd7" }}>
                Request calls to be recorded
              </label>
            </div> */}
            <div className="d-flex justify-content-end gap-2">
              <Form.Item labelAlign="left" className="labelTop">
                <Button
                  className="btn btntransparent"
                  htmlType="button"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item labelAlign="left" className="labelTop">
                <Button
                  className="btn btnsm"
                  htmlType="submit"
                  type="primary"
                  disabled={spinner}
                // onClick={handleSubmit}
                >
                  Submit {spinner && <LoadingOutlined className="mb-1" />}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal >
    </>
  );
};
